import { combineReducers } from "redux";

import Region from "./Region";
import Employee from "./Employee";
import Company from "./Company";
import Stand from "./Stand";
import Days from "./Days";

export default combineReducers({
  Region,
  Company,
  Employee,
  Stand,
  Days,
});
