import { combineReducers } from "redux";

import User from "./User";
import Time from "./Time";
import Rating from "./Rating";

export default combineReducers({
  User,
  Time,
  Rating,
});
